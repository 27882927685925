<!-- Loading bar -->
<fuse-loading-bar></fuse-loading-bar>

<!-- Navigation -->
<fuse-vertical-navigation
    id="vertical-nav"
    class="bg-white light print:hidden"
    [mode]="isScreenSmall ? 'over' : 'side'"
    [name]="'mainNavigation'"
    [navigation]="getNavigation()"
    [opened]="!isScreenSmall">
    <!-- Navigation header hook -->
    <ng-container fuseVerticalNavigationContentHeader>
        <div class="w-full p-4 pl-6 rounded-xl cursor-pointer
                    hover:scale-105 transition-all duration-250 ease-in-out delay-75"
                    [routerLink]="['/dashboard']">

            <div class="flex items-center mb-4">
                <img class="w-auto h-12 ring-bg-card" [src]="_authService.getLogoPath()">
            </div>

            <div class="flex flex-col items-start">
                <ng-template #noCompany>
                    <span class="font-semibold text-2xl">{{'section.dashboard.no_company' | translate}}</span>
                </ng-template>
                <span class="text-primary text-lg font-bold -mt-1">{{user?.firstname}} {{user?.lastname}}</span>
            </div>

<!--            <div class="flex items-center ml-auto">-->
<!--                 <user [showAvatar]="false"></user> -->
<!--            </div>-->
        </div>
        <!-- User -->
        <div class="flex flex-col items-center w-full p-4">
            <!-- <div class="relative w-24 h-24">

                <mat-icon
                    class="icon-size-24"
                    [svgIcon]="'heroicons_solid:user-circle'"></mat-icon>
            </div> -->
            <!-- <div class="flex flex-col items-center justify-center w-full mt-6">
                <div class="w-full whitespace-nowrap text-ellipsis overflow-hidden text-center leading-normal font-medium">
                    {{user.userName}} {{user.userName}}
                </div>
                <div class="w-full mt-0.5 whitespace-nowrap text-ellipsis overflow-hidden text-center text-md leading-normal font-medium text-secondary">
                    {{user.userName}}
                </div>
            </div> -->
        </div>
    </ng-container>
</fuse-vertical-navigation>

<!-- Wrapper -->
<div class="flex flex-col flex-auto w-full min-w-0">

    <!-- Header -->
    <div class="relative flex flex-0 items-center justify-between w-full h-16 px-4 md:px-6 z-49 shadow dark:shadow-none dark:border-b bg-card dark:bg-transparent print:hidden">
        <!-- Navigation toggle button -->
        <button
            mat-icon-button
            (click)="toggleNavigation('mainNavigation')">
            <mat-icon [svgIcon]="'feather:menu'"></mat-icon>
        </button>

        <div class="flex items-center">

            <!-- <type-change class="mr-2"></type-change> -->
            <languages class="mr-2"></languages>
          <user [showAvatar]="false"></user>
        </div>

    </div>

    <!-- Content -->
    <div class="flex flex-col flex-auto">
        <router-outlet *ngIf="true"></router-outlet>

    </div>

</div>
