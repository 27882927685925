/* tslint:disable:max-line-length */
import { FuseNavigationItem } from 'fuseui';

export const defaultNavigation: FuseNavigationItem[] = [
    {
        id: 'section.menu.dashboard',
        title: 'Přehled',
        type: 'basic',
        icon: 'heroicons_outline:home',
        link: '/dashboard',
        role: 'other',
    },
    {
        id: 'section.menu.users',
        title: 'Uživatelé',
        type: 'basic',
        icon: 'heroicons_outline:users',
        link: '/users',
        role: 'other',
    },
    {
        id: 'section.menu.workspaces',
        title: 'Pracoviště',
        type: 'basic',
        icon: 'heroicons_outline:office-building',
        link: '/workspaces',
        role: 'other',
    },
    {
        id: 'section.menu.work_position',
        title: 'Pracovní pozice',
        type: 'basic',
        icon: 'heroicons_outline:briefcase',
        link: '/work-position',
    },
    {
        id: 'section.menu.audits',
        title: 'Audity',
        type: 'basic',
        icon: 'heroicons_outline:clipboard-check',
        link: '/audits',
    },
    {
        id: 'section.menu.norms',
        title: 'Normy',
        type: 'basic',
        icon: 'heroicons_outline:clipboard-list',
        link: '/norms',
    },
    {
        id: 'section.menu.mismatches',
        title: 'Neshody',
        type: 'basic',
        icon: 'heroicons_outline:x-circle',
        link: '/mismatches',
    },
    {
        id: 'section.menu.app-group-3',
        title: 'Aplikace QMSystem',
        subtitle: 'section.menu.app-group-3',
        type: 'group',
        children: [
            {
                id: 'section.menu.support',
                title: 'Podpora',
                type: 'basic',
                icon: 'heroicons_outline:question-mark-circle',
                link: '/support',
            },
            {
                id: 'section.menu.changelog',
                title: 'Changelog',
                type: 'basic',
                icon: 'heroicons_outline:newspaper',
                link: '/changelog',
            },
        ],
    },
];

export const defaultNavigationNotAdmin: FuseNavigationItem[] = [
    {
        id: 'section.menu.dashboard',
        title: 'Přehled',
        type: 'basic',
        icon: 'heroicons_outline:home',
        link: '/dashboard',
        role: 'other',
    },
    {
        id: 'section.menu.app-group',
        title: 'Aplikace QMSystem',
        subtitle: 'section.menu.app-group-3',
        type: 'group',
        children: [
            {
                id: 'section.menu.privacy',
                title: 'Podmínky',
                type: 'basic',
                icon: 'heroicons_outline:information-circle',
                link: '/sign-privacy',
            },
            {
                id: 'section.menu.support',
                title: 'Podpora',
                type: 'basic',
                icon: 'heroicons_outline:question-mark-circle',
                link: '/support',
            },
            {
                id: 'section.menu.changelog',
                title: 'Changelog',
                type: 'basic',
                icon: 'heroicons_outline:newspaper',
                link: '/changelog',
            },
        ],
    },
];

export const defaultNavigationSeller: FuseNavigationItem[] = [
    {
        id: 'section.menu.dashboard',
        title: 'Přehled',
        type: 'basic',
        icon: 'heroicons_outline:home',
        link: '/dashboard',
        role: 'other',
    },
    {
        id: 'section.menu.app-group',
        title: 'Správa',
        subtitle: 'section.menu.app-group-seller',
        type: 'group',
        children: [
            {
                id: 'section.menu.seller-info',
                title: 'company',
                type: 'basic',
                icon: 'heroicons_outline:briefcase',
                link: '/seller/about',
            },
            {
                id: 'section.menu.seller-users',
                title: 'company',
                type: 'basic',
                icon: 'heroicons_outline:users',
                link: '/seller/users',
            },
            {
                id: 'section.menu.seller-shops',
                title: 'company',
                type: 'basic',
                icon: 'heroicons_outline:shopping-cart',
                link: '/seller/shops',
            },
            {
                id: 'section.menu.seller-offers',
                title: 'company',
                type: 'basic',
                icon: 'heroicons_outline:shopping-bag',
                link: '/seller/offers',
            },
            {
                id: 'section.menu.seller-documents',
                title: 'company',
                type: 'basic',
                icon: 'heroicons_outline:paper-clip',
                link: '/seller/documents',
            },
        ],
    },
    {
        id: 'section.menu.app-group',
        title: 'Aplikace QMSystem',
        subtitle: 'section.menu.app-group-3',
        type: 'group',
        children: [
            {
                id: 'section.menu.privacy',
                title: 'Podmínky',
                type: 'basic',
                icon: 'heroicons_outline:information-circle',
                link: '/sign-privacy',
            },
            {
                id: 'section.menu.support',
                title: 'Podpora',
                type: 'basic',
                icon: 'heroicons_outline:question-mark-circle',
                link: '/support',
            },
            {
                id: 'section.menu.changelog',
                title: 'Changelog',
                type: 'basic',
                icon: 'heroicons_outline:newspaper',
                link: '/changelog',
            },
        ],
    },
];

export const defaultNavigationCompany: FuseNavigationItem[] = [
    {
        id: 'section.menu.dashboard',
        title: 'Přehled ',
        type: 'basic',
        icon: 'heroicons_outline:home',
        link: '/dashboard',
        role: 'other',
    },
    {
        id: 'section.menu.app-group',
        title: 'Správa',
        subtitle: 'section.menu.app-group-company',
        type: 'group',
        children: [
            {
                id: 'section.menu.company-info',
                title: 'company',
                type: 'basic',
                icon: 'heroicons_outline:briefcase',
                link: '/company/about',
            },
            {
                id: 'section.menu.company-users',
                title: 'company',
                type: 'basic',
                icon: 'heroicons_outline:users',
                link: '/company/users',
            },
            {
                id: 'section.menu.company-blacklist',
                title: 'company',
                type: 'basic',
                icon: 'heroicons_outline:document-remove',
                link: '/company/blacklist',
            },
            {
                id: 'section.menu.company-documents',
                title: 'company',
                type: 'basic',
                icon: 'heroicons_outline:paper-clip',
                link: '/company/documents',
            },
        ],
    },
    {
        id: 'section.menu.app-group',
        title: 'Aplikace QMSystem',
        subtitle: 'section.menu.app-group-3',
        type: 'group',
        children: [
            {
                id: 'section.menu.privacy',
                title: 'Podmínky',
                type: 'basic',
                icon: 'heroicons_outline:information-circle',
                link: '/sign-privacy',
            },
            {
                id: 'section.menu.support',
                title: 'Podpora',
                type: 'basic',
                icon: 'heroicons_outline:question-mark-circle',
                link: '/support',
            },
            {
                id: 'section.menu.changelog',
                title: 'Changelog',
                type: 'basic',
                icon: 'heroicons_outline:newspaper',
                link: '/changelog',
            },
        ],
    },
];

export const compactNavigation: FuseNavigationItem[] = [];
export const futuristicNavigation: FuseNavigationItem[] = [];
export const horizontalNavigation: FuseNavigationItem[] = [];
export const horizontalNavigationNotAdmin: FuseNavigationItem[] = [];
