import {Injectable} from '@angular/core';
import {cloneDeep} from 'lodash-es';

import {
    compactNavigation,
    defaultNavigation, defaultNavigationCompany,
    defaultNavigationNotAdmin, defaultNavigationSeller,
    futuristicNavigation,
    horizontalNavigation, horizontalNavigationNotAdmin
} from "./data";

import {FuseMockApiService, FuseNavigationItem} from "fuseui";
import {UserService} from 'qms';


@Injectable({
    providedIn: 'root'
})
export class NavigationMockApi {

    private readonly _compactNavigation: FuseNavigationItem[] = compactNavigation;
    private readonly _defaultNavigation: FuseNavigationItem[] = defaultNavigation;
    private readonly _defaultNavigationSeller: FuseNavigationItem[] = defaultNavigationSeller;
    private readonly _defaultNavigationCompany: FuseNavigationItem[] = defaultNavigationCompany;
    private readonly _futuristicNavigation: FuseNavigationItem[] = futuristicNavigation;
    private readonly _horizontalNavigation: FuseNavigationItem[] = horizontalNavigation;
    private readonly _defaultNavigationNotAdmin: FuseNavigationItem[] = defaultNavigationNotAdmin;
    private readonly _horizontalNavigationNotAdmin: FuseNavigationItem[] = horizontalNavigationNotAdmin;

    /**
     * Constructor
     */
    constructor(private _fuseMockApiService: FuseMockApiService, private _userService: UserService) {
        // Register Mock API handlers
        this.registerHandlers();
    }

    // -----------------------------------------------------------------------------------------------------
    // @ Public methods
    // -----------------------------------------------------------------------------------------------------

    /**
     * Register Mock API handlers
     */
    registerHandlers(): void {

        console.log('Registering handlers')
        // -----------------------------------------------------------------------------------------------------
        // @ Navigation - GET
        // -----------------------------------------------------------------------------------------------------
        this._fuseMockApiService
            .onGet('api/common/navigation')
            .reply(() => {

                // Fill compact navigation children using the default navigation
                this._compactNavigation.forEach((compactNavItem) => {
                    this._defaultNavigation.forEach((defaultNavItem) => {
                        if (defaultNavItem.id === compactNavItem.id) {
                            compactNavItem.children = cloneDeep(defaultNavItem.children);
                        }
                    });
                });

                // Fill futuristic navigation children using the default navigation
                this._futuristicNavigation.forEach((futuristicNavItem) => {
                    this._defaultNavigation.forEach((defaultNavItem) => {
                        if (defaultNavItem.id === futuristicNavItem.id) {
                            futuristicNavItem.children = cloneDeep(defaultNavItem.children);
                        }
                    });
                });

                // Fill horizontal navigation children using the default navigation
                this._horizontalNavigation.forEach((horizontalNavItem) => {
                    this._defaultNavigation.forEach((defaultNavItem) => {
                        if (defaultNavItem.id === horizontalNavItem.id) {
                            horizontalNavItem.children = cloneDeep(defaultNavItem.children);
                        }
                    });
                });

                // Return the response
                return [
                    200,
                    {
                        compact: cloneDeep(this._compactNavigation),
                        default: cloneDeep(this._defaultNavigation),
                        defaultSeller: cloneDeep(this._defaultNavigationSeller),
                        defaultCompany: cloneDeep(this._defaultNavigationCompany),
                        futuristic: cloneDeep(this._futuristicNavigation),
                        horizontal: cloneDeep(this._horizontalNavigation),
                        defaultNotAdmin: cloneDeep(this._defaultNavigationNotAdmin),
                        horizontalNotAdmin: cloneDeep(this._horizontalNavigationNotAdmin)
                    }
                ];
            });
    }

}
