import { Route } from '@angular/router';
import { InitialDataResolver } from './app.resolvers';
import { AuthGuard, LayoutComponent, NoAuthGuard, AuthGuardSign } from 'qms';
import { AuthResetLinkModule } from '../../../../libs/qms/src/lib/auth/resend-link/resend-link.module';

// @formatter:off
/* eslint-disable max-len */
/* eslint-disable @typescript-eslint/explicit-function-return-type */
export const appRoutes: Route[] = [
    // Redirect empty path to '/example'
    {
        path: '',
        pathMatch: 'full',
        redirectTo: 'dashboard',
        data: { title: 'QMSystem' },
    },

    // Redirect signed in user
    //
    // After the user signs in, the sign in page will redirect the user to the 'signed-in-redirect'
    // path. Below is another redirection for that path to redirect the user to the desired
    // location. This is a small convenience to keep all main routes together here on this file.
    {
        path: 'signed-in-redirect',
        pathMatch: 'full',
        redirectTo: 'dashboard',
        data: { title: 'QMSystem' },
    },

    // Auth routes for guests
    {
        path: '',
        canActivate: [NoAuthGuard],
        canActivateChild: [NoAuthGuard],
        component: LayoutComponent,
        data: {
            layout: 'empty',
        },
        children: [
            {
                path: 'sign-in',
                loadChildren: () =>
                    import(
                        '../../../../libs/qms/src/lib/auth/sign-in/sign-in.module'
                    ).then((m) => m.AuthSignInModule),
                data: { title: 'Přihášení | QMSystem' },
            },
            {
                path: 'sign-up',
                loadChildren: () =>
                    import(
                        '../../../../libs/qms/src/lib/auth/sign-up/sign-up.module'
                    ).then((m) => m.AuthSignUpModule),
                data: { title: 'Registrace | QMSystem' },
            },
            {
                path: 'confirmation-success',
                loadChildren: () =>
                    import(
                        '../../../../libs/qms/src/lib/auth/confirmation-success/confirmation-success.module'
                    ).then((m) => m.AuthConfirmationSuccessModule),
                data: { title: 'QMSystem' },
            },
            {
                path: 'confirmation-required',
                loadChildren: () =>
                    import(
                        '../../../../libs/qms/src/lib/auth/confirmation-required/confirmation-required.module'
                    ).then((m) => m.AuthConfirmationRequiredModule),
                data: { title: 'QMSystem' },
            },
            {
                path: 'forgot-password',
                loadChildren: () =>
                    import(
                        '../../../../libs/qms/src/lib/auth/forgot-password/forgot-password.module'
                    ).then((m) => m.AuthForgotPasswordModule),
                data: { title: 'Zapomenuté heslo | QMSystem' },
            },
            {
                path: 'changepass',
                loadChildren: () =>
                    import(
                        '../../../../libs/qms/src/lib/auth/reset-password/reset-password.module'
                    ).then((m) => m.AuthResetPasswordModule),
                data: { title: 'QMSystem' },
            },
            {
                path: 'newpass',
                loadChildren: () =>
                    import(
                        '../../../../libs/qms/src/lib/auth/new-password/new-password.module'
                    ).then((m) => m.AuthNewPasswordModule),
                data: { title: 'Nové heslo | QMSystem' },
            },
            {
                path: 'privacy',
                loadChildren: () =>
                    import(
                        '../../../../libs/qms/src/lib/pages/privacy/privacy.module'
                    ).then((m) => m.PrivacyModule),
                data: { title: 'Podmínky | QMSystem' },
            },
            {
                path: 'resend-link',
                loadChildren: () =>
                    import(
                        '../../../../libs/qms/src/lib/auth/resend-link/resend-link.module'
                    ).then((m) => m.AuthResetLinkModule),
                data: { title: 'Aktivace | QMSystem' },
            },
        ],
    },

    // Auth routes for authenticated users
    {
        path: '',
        canActivate: [AuthGuard],
        canActivateChild: [AuthGuard],
        component: LayoutComponent,
        data: {
            layout: 'empty',
        },
        children: [
            {
                path: 'sign-out',
                loadChildren: () =>
                    import(
                        '../../../../libs/qms/src/lib/auth/sign-out/sign-out.module'
                    ).then((m) => m.AuthSignOutModule),
                data: { title: 'Odhlášení | QMSystem' },
            },
        ],
    },

    // Admin routes
    {
        path: '',
        canActivate: [AuthGuard],
        canActivateChild: [AuthGuard],
        component: LayoutComponent,

        resolve: {
            initialData: InitialDataResolver,
        },
        children: [
            {
                path: 'users',
                loadChildren: () =>
                    import(
                        '../../../../libs/qms/src/lib/users/users.module'
                    ).then((m) => m.UsersModule),
                data: { title: 'Správa Uživatelů | QMSystem' },
            },
            {
                path: 'dashboard',
                loadChildren: () =>
                    import(
                        '../../../../libs/qms/src/lib/dashboard/project/project.module'
                    ).then((m) => m.ProjectModule),
                data: { title: 'QMSystem' },
            },
            {
                path: 'support',
                loadChildren: () =>
                    import(
                        '../../../../libs/qms/src/lib/pages/support/support.module'
                    ).then((m) => m.SupportModule),
                data: { title: 'Podpora | QMSystem' },
            },
            {
                path: 'changelog',
                loadChildren: () =>
                    import(
                        '../../../../libs/qms/src/lib/pages/changelog/changelog.module'
                    ).then((m) => m.ChangelogModule),
                data: { title: 'QMSystem' },
            },
            {
                path: 'profile',
                loadChildren: () =>
                    import(
                        '../../../../libs/qms/src/lib/profile/profile.module'
                    ).then((m) => m.ProfileModule),
                data: { title: 'Správa profilu | QMSystem' },
            },
            {
                path: 'work-position',
                loadChildren: () =>
                    import(
                        '../../../../libs/qms/src/lib/work-position/work-position.module'
                    ).then((m) => m.PositionsModule),
                data: { title: 'Správa pracovních pozic | QMSystem' },
            },
            {
                path: 'workspaces',
                loadChildren: () =>
                    import(
                        '../../../../libs/qms/src/lib/workspaces/workspaces.module'
                    ).then((m) => m.WorkspacesModule),
                data: { title: 'Správa pracovišť | QMSystem' },
            },
            {
                path: 'audits',
                loadChildren: () =>
                    import(
                        '../../../../libs/qms/src/lib/audits/audits.module'
                    ).then((m) => m.AuditsModule),
                data: { title: 'Správa auditů | QMSystem' },
            },
            {
                path: 'norms',
                loadChildren: () =>
                    import(
                        '../../../../libs/qms/src/lib/norms/norms.module'
                    ).then((m) => m.NormsModule),
                data: { title: 'Správa norem | QMSystem' },
            },
            {
                path: 'mismatches',
                loadChildren: () =>
                    import(
                        '../../../../libs/qms/src/lib/mismatches/mismatches.module'
                    ).then((m) => m.MismatchesModule),
                data: { title: 'Správa neshod | QMSystem' },
            },

            // maintenance, error & Catch all
            {
                path: 'maintenance',
                loadChildren: () =>
                    import(
                        '../../../../libs/qms/src/lib/pages/maintenance/maintenance.module'
                    ).then((m) => m.MaintenanceModule),
                data: { title: 'QMSystem' },
            },
            {
                path: '500-internal-server-error',
                loadChildren: () =>
                    import(
                        '../../../../libs/qms/src/lib/pages/error/error-500/error-500.module'
                    ).then((m) => m.Error500Module),
                data: { title: 'QMSystem' },
            },
            {
                path: '404-not-found',
                pathMatch: 'full',
                loadChildren: () =>
                    import(
                        '../../../../libs/qms/src/lib/pages/error/error-404/error-404.module'
                    ).then((m) => m.Error404Module),
                data: { title: '404 | QMSystem' },
            },
            {
                path: '**',
                redirectTo: '404-not-found',
                data: { title: '404 | QMSystem' },
            },
        ],
    },
];
