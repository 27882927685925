import {ChangeDetectorRef, Component, OnInit, ViewEncapsulation} from '@angular/core';
import {FormBuilder, FormGroup, Validators} from '@angular/forms';
import {ActivatedRoute, Params, Router} from '@angular/router';
import {fuseAnimations} from 'fuseui';
import {AuthService} from '../auth.service';
import {TranslateService} from '@ngx-translate/core';
import {ToastrService} from 'ngx-toastr';

import {MatCheckboxChange} from '@angular/material/checkbox';
import {MatButton} from '@angular/material/button';
import {AddressObject} from '../../utils/google-geo/google-geo/types';
import {phoneInputControls} from '../../utils/phone-input/phone-input.controls';


@Component({
    selector: 'auth-sign-up',
    templateUrl: './sign-up.component.html',
    styleUrls: ['./sign-up.styles.scss'],
    encapsulation: ViewEncapsulation.None,
    animations: fuseAnimations
})
export class AuthSignUpComponent implements OnInit {
    readonly phoneInputControls = phoneInputControls;

    signUpForm!: FormGroup;

    countries = [{name: 'Česká republika', id: 'Česká republika'}, {name: 'Slovensko', id: 'Slovensko'}];
    tooltipPin = '';

    error: any;
    fillFromIco = ['name', 'addressCountry', 'addressCity', 'addressStreet', 'addressZip', 'idNumber', 'vatNumber'];
    parsedAddress?: { address: string };
    geoAddress: AddressObject = {};

    constructor(
        private _authService: AuthService,
        private _formBuilder: FormBuilder,
        private _router: Router,
        private _activatedRoute: ActivatedRoute,
        public translateService: TranslateService,
        private _changeDetectorRef: ChangeDetectorRef,
        private toastr: ToastrService,
        private route: ActivatedRoute,
    ) {
    }

    ngOnInit(): void {
        this.tooltipPin = this.translateService.instant('auth.signup.tooltip_pin');

        this.route.queryParams.subscribe((params: Params) => {
            if (params['error'] !== null && params['error']) {
                this.error = params['error'];
                if (this.error === 'complete') {
                    this.toastr.error(this.translateService.instant('section.signup.error_complete'));
                } else if (this.error === 'error') {
                    this.toastr.error();
                }
            }
        });

        this.signUpForm = this._formBuilder.group({

            firstname: ['', Validators.required],
            lastname: ['', Validators.required],
            email: ['', [Validators.required, Validators.email]],
            password: ['', [Validators.required, this._authService.validatePassword.bind(this._authService)]],
            passwordConfirmation: ['', [Validators.required]],
            address: [null],
            phone: [''],
            checkBox: [null, [Validators.requiredTrue]],

        }, {validator: this._authService.checkPasswords.bind(this._authService)});

    }

    refactorForBe() {

        if (this.signUpForm) {
            return {
                firstname: this.signUpForm.get(['userFirstname'])?.value,
                lastname: this.signUpForm.get(['userLastname'])?.value,
                email: this.signUpForm.get(['email'])?.value,
                password: this.signUpForm.get(['password'])?.value,
                passwordConfirmation: this.signUpForm.get(['passwordConfirmation'])?.value,
                phone: this.signUpForm.get(['phone'])?.value?.internationalNumber,
                address: this.geoAddress
            };
        } else {
            return null;
        }
    }

    async signUp(submitBtn: MatButton) {
        // Do nothing if the form is invalid
        if (this.signUpForm.invalid) {
            this.signUpForm.markAsDirty();
            this._changeDetectorRef.markForCheck();
            return;
        }


        // Disable the form
        this.signUpForm.disable();
        submitBtn.disabled = true;

        // Sign up
        this._authService.signUp(this.refactorForBe())
            .subscribe(
                () => {
                    this.toastr.success(this.translateService.instant('section.sign-up.acc_created'));
                    this._router.navigateByUrl('/confirmation-required');
                },
                (response: { error: { message: string } }) => {
                    this.toastr.error(this.translateService.instant(response?.error?.message));
                    // Re-enable the form
                    this.signUpForm.enable();
                    submitBtn.disabled = false;
                    this._changeDetectorRef.markForCheck();

                }
            );
    }

    addressObtained($event: AddressObject) {
        this.geoAddress.formattedAddress = $event.formattedAddress;
        this.geoAddress.addressComponents = $event.addressComponents;
        this.geoAddress.longitude = $event.longitude;
        this.geoAddress.latitude = $event.latitude;
        this.geoAddress.placeId = $event.placeId;

        if (this.signUpForm) {
            this.signUpForm.controls['address'].setValue(this.geoAddress);
        }
        this._changeDetectorRef.markForCheck();
    }

}
