import {Injectable} from '@angular/core';
import {TranslateService} from '@ngx-translate/core';
import {HttpClient} from '@angular/common/http';

const DEFAULT_LANG = 'cs';
const SUPPORTED_LANGS = ['cs', 'en', 'de'];

@Injectable()
export class LanguageService {

    constructor(
        public translateService: TranslateService,
        private _httpClient: HttpClient,
    ) {
    }

    set appLanguage(lang: string) {
        console.log('Language was set up: ', lang);
        localStorage.setItem('language', lang);
    }

    get appLanguage(): string {
        // @ts-ignore
        return localStorage.getItem('language');
    }

    get defaultLanguage(): string {
        return DEFAULT_LANG;
    }

    get supportedLanguages(): string[] {
        return SUPPORTED_LANGS;
    }

    getBrowserLanguage(): string | undefined {
        return this.translateService.getBrowserLang();
    }

    initAppLanguage() {
        if (this.appLanguage) {
            this.translateService.setDefaultLang(this.appLanguage);
            this.translateService.use(this.appLanguage);
        } else if (this.getBrowserLanguage() && SUPPORTED_LANGS.includes(this.getBrowserLanguage() as string)) {
            this.translateService.setDefaultLang(this.getBrowserLanguage() as string);
            this.translateService.use(this.getBrowserLanguage() as string);
            this.appLanguage = this.getBrowserLanguage() as string;
        } else {
            this.translateService.setDefaultLang(this.defaultLanguage);
            this.translateService.use(this.defaultLanguage);
            this.appLanguage = this.defaultLanguage;
        }
    }

}
