import {ChangeDetectorRef, Component, OnDestroy, OnInit, ViewEncapsulation} from '@angular/core';
import {ActivatedRoute, Router} from '@angular/router';
import {Subject, takeUntil} from 'rxjs';
import {FuseMediaWatcherService} from 'fuseui';
import {FuseNavigationService, FuseVerticalNavigationComponent} from 'fuseui';
import {Navigation} from '../../../../../../../../apps/fuse/src/app/core/navigation/navigation.types';
import {NavigationService} from '../../../../../../../../apps/fuse/src/app/core/navigation/navigation.service';
import {UserService} from '../../../../../../../../apps/fuse/src/app/core/user/user.service';
import {UserResource} from 'qms';
import {AuthService} from '../../../../auth/auth.service';
import {TranslateService} from '@ngx-translate/core';
import {MatDialog} from '@angular/material/dialog';


@Component({
    selector: 'classy-layout',
    templateUrl: './classy.component.html',
    encapsulation: ViewEncapsulation.None
})
export class ClassyLayoutComponent implements OnInit, OnDestroy {

    isScreenSmall?: boolean;
    navigation?: Navigation;
    user?: UserResource;
    private _unsubscribeAll: Subject<any> = new Subject<any>();
    isAdmin = false;
    isCompany = false;
    isSeller = false;

    constructor(
        private _activatedRoute: ActivatedRoute,
        private _router: Router,
        private _navigationService: NavigationService,
        private _userService: UserService,
        private _fuseMediaWatcherService: FuseMediaWatcherService,
        private _fuseNavigationService: FuseNavigationService,
        public _authService: AuthService,
        public _transateService: TranslateService,
        private _changeDetectorRef: ChangeDetectorRef,
        public dialog: MatDialog,
    ) {
    }

    get currentYear(): number {
        return new Date().getFullYear();
    }

    async ngOnInit(): Promise<void> {
        // Subscribe to navigation data
        this._navigationService.navigation$
            .pipe(takeUntil(this._unsubscribeAll))
            .subscribe((navigation: Navigation) => {
                this.navigation = navigation;
            });

        this.user = await this._authService.profile().toPromise();

        // Subscribe to media changes
        this._fuseMediaWatcherService.onMediaChange$
            .pipe(takeUntil(this._unsubscribeAll))
            .subscribe(({matchingAliases}) => {
                this._changeDetectorRef.markForCheck();
                // Check if the screen is small
                this.isScreenSmall = !matchingAliases.includes('md');
            });

        this.isAdmin = await this._authService.isAdmin();

        this._changeDetectorRef.markForCheck();

    }

    ngOnDestroy(): void {
        this._unsubscribeAll.next(null);
        this._unsubscribeAll.complete();
    }

    toggleNavigation(name: string): void {
        const navigation = this._fuseNavigationService.getComponent<FuseVerticalNavigationComponent>(name);

        if (navigation) {
            navigation.toggle();
        }
    }

    getNavigation() {
        if (this.navigation) {
            if (this.isSeller) {
                return this.navigation.defaultSeller;
            } else if (this.isCompany) {
                return this.navigation.defaultCompany;
            } else if (this.isAdmin) {
                return this.navigation.default;
            } else {
                return this.navigation.defaultNotAdmin;
            }
        } else {
            return null;
        }
    }


}
