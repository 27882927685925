import { platformBrowserDynamic } from '@angular/platform-browser-dynamic';
import { AppModule } from './app/app.module';
import * as Sentry from '@sentry/angular-ivy';
import {environment} from '../../../libs/qms/src/environments/environment';


Sentry.init({
    dsn: "https://1da639ed1f6ed5942a7dd2c57d4bdfb9@o1210468.ingest.us.sentry.io/4507326668668928",
    integrations: [
        Sentry.browserTracingIntegration(),
        Sentry.replayIntegration(),
    ],
    // Performance Monitoring
    tracesSampleRate: 1.0, //  Capture 100% of the transactions
    environment: environment.development ? 'development' : 'production',
    enabled: !environment.development,

    // Set 'tracePropagationTargets' to control for which URLs distributed tracing should be enabled
    tracePropagationTargets: [ /^https:\/\/dev-qms\.techcrowd\.space\.cz/, /^https:\/\/qms\.techcrowd\.space\.cz/],
    // Session Replay
    replaysSessionSampleRate: 0.1, // This sets the sample rate at 10%. You may want to change it to 100% while in development and then sample at a lower rate in production.
    replaysOnErrorSampleRate: 1.0, // If you're not already sampling the entire session, change the sample rate to 100% when sampling sessions where errors occur.
});

platformBrowserDynamic().bootstrapModule(AppModule)
                        .catch(err => console.error(err));
