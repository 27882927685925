import { NgModule } from '@angular/core';
import { AuthModule } from './auth';
import {BASE_PATH} from 'qms';
import {environment} from '../environments/environment';

@NgModule({
    imports: [AuthModule],
    providers: [{provide: BASE_PATH, useValue: environment.apiUrl}],
    exports: [],
})
export class QmsModule {}
