import {
    ChangeDetectorRef,
    Component,
    EventEmitter,
    Input,
    NgZone,
    OnDestroy,
    OnInit,
    Output, SimpleChanges,
    ViewChild
} from '@angular/core';
import {FormBuilder} from '@angular/forms';
import {AgmMap} from '@agm/core';
import {Appearance, Location} from '@angular-material-extensions/google-maps-autocomplete';
import {AddressObject, Marker} from './types';
import PlaceResult = google.maps.places.PlaceResult;
import {TranslateService} from '@ngx-translate/core';

@Component({
    selector: 'app-google-geo',
    templateUrl: './google-geo.component.html'
})
export class GoogleGeoComponent implements OnInit, OnDestroy {

    @Input() fullAddress?: string;
    @Input() showMap = true;
    @Input() parsedAddress?: { address: string};
    @Input() inLatitude?: string;
    @Input() inLongitude?: string;
    @Input() mandatory?: boolean;
    @Output() addressEmitted: EventEmitter<AddressObject> = new EventEmitter<AddressObject>();
    @Output() errorEmited: EventEmitter<boolean> = new EventEmitter<boolean>();

    @ViewChild('map') public agmMap: AgmMap | undefined;

    public appearance = Appearance;

    public zoom?: number;
    marker?: Marker;
    private mapClickListener: any;
    private map: google.maps.Map | undefined;
    customMarker = false;
    latitude?: number;
    longitude?: number;
    private geocoder: google.maps.Geocoder | undefined;
    address: AddressObject = {};
    countries = ['cz', 'sk'];
    touched = false;
    isMapOpen = false;
    private placesService: any;
    placeholderText = '';
    constructor(private _formBuilder: FormBuilder,
                private _changeDetectorRef: ChangeDetectorRef,
                private translateService: TranslateService,
                private zone: NgZone
    ) {
    }

    ngOnInit(): void {
        this.placesService = new google.maps.places.PlacesService(document.createElement('div'));
        this.placeholderText = this.translateService.instant('section.geo.placeholder');

        this.zoom = 7;
        this.latitude = 49.66056346200483;
        this.longitude = 15.390425091058537;
        this.renderMap();
        if (this.fullAddress) {
            this.address.formattedAddress = this.fullAddress;
            if (this.inLatitude && this.inLongitude) {
                this.latitude = +this.inLatitude;
                this.longitude = +this.inLongitude;
                this.marker = {
                    lat: this.latitude,
                    lng: this.longitude,
                    draggable: false
                };
            }
            this._changeDetectorRef.detectChanges();
        }
    }

    ngOnChanges(changes: SimpleChanges): void {
        if (changes.parsedAddress && !changes.parsedAddress.firstChange) {
            this.onFullAddressChange();
        }
    }

    onFullAddressChange() {
        const addressQuery = this.parsedAddress?.address;

        const requestTest = {
            query: addressQuery
        };
        // Use the PlacesService to perform the search
        this.placesService.textSearch(requestTest, (resultsText: any[], statusTest: string) => {
            this.zone.run(() => {
                if (statusTest === google.maps.places.PlacesServiceStatus.OK) {

                    const requestDetail = {
                        placeId: resultsText[0]?.place_id, // Replace with the actual place ID
                    };

                    this.placesService.getDetails(requestDetail, (result, status) => {
                        this.zone.run(() => {
                            if (status === google.maps.places.PlacesServiceStatus.OK) {

                                this.address.formattedAddress = undefined;
                                this._changeDetectorRef.detectChanges();

                                this.address.formattedAddress = result?.formatted_address;
                                this.address.placeId = result?.place_id;
                                this.address.addressComponents = result?.address_components;

                                if (result?.geometry && result?.geometry.location) {
                                    const location = result?.geometry.location;
                                    this.latitude = location.lat();
                                    this.longitude = location.lng();
                                    if (this.map) {
                                        this.map.setCenter(location);
                                        this.animateMapZoomTo(this.map, 12);
                                    }
                                    if (this.latitude && this.longitude) {
                                        this.marker = {
                                            lat: this.latitude,
                                            lng: this.longitude,
                                            draggable: false
                                        };
                                    }
                                    this.address.latitude = this.latitude;
                                    this.address.longitude = this.longitude;
                                }
                                this._changeDetectorRef.markForCheck();
                                this.emitAddress();
                            } else {
                                console.error('Error in PlacesService:', status);
                            }
                        });
                    });



                    this._changeDetectorRef.detectChanges();
                } else {
                    // Handle error
                    console.error(status);
                }
            });
        });
    }

    ngOnDestroy(): void {
        if (this.mapClickListener) {
            this.mapClickListener.remove();
        }
    }

    renderMap(): void {
        if (this.agmMap) {
            this.agmMap.triggerResize();
            this._changeDetectorRef.detectChanges();
        }
    }

    onAutocompleteSelected(result: PlaceResult) {
        this.customMarker = false;
        this.address.formattedAddress = result?.formatted_address;
        this.address.addressComponents = result?.address_components;
        this.address.placeId = result?.place_id;
        // If coords are not present in emited value - Add this.emitAddress(); here.
    }

    onLocationSelected(location: Location) {
        this.latitude = location.latitude;
        this.longitude = location.longitude;
        if (this.map) {
            this.animateMapZoomTo(this.map, 12);
        }
        this.marker = {
            lat: location.latitude,
            lng: location.longitude,
            draggable: false
        };
        this.address.latitude = location.latitude;
        this.address.longitude = location.longitude;
        this.emitAddress();
        this._changeDetectorRef.markForCheck();
    }

    clickedMarker() {
        if (this.marker) {
            // Možnost odstranění markeru při kliku na něj.
            // this.marker = undefined;
        }
        this._changeDetectorRef.detectChanges();
    }

    public mapReadyHandler(map: google.maps.Map): void {
        this.map = map;
        if (this.fullAddress) {
            this.animateMapZoomTo(this.map, 12);
        }
        this.mapClickListener = this.map.addListener('click', (e: google.maps.MouseEvent) => {
            this.zone.run(() => {
                if (this.customMarker) {
                    this.marker = {
                        lat: e.latLng.lat(),
                        lng: e.latLng.lng(),
                        draggable: false
                    };
                    this.address.latitude = e.latLng.lat();
                    this.address.longitude = e.latLng.lng();
                    this.geocodeLatLng();
                    this._changeDetectorRef.markForCheck();
                }
            });
        });
    }

    toggleMap() {
        this.isMapOpen = !this.isMapOpen;
    }

    geocodeLatLng() {
        if (this.marker) {
            const latlng = {
                lat: this.marker.lat,
                lng: this.marker.lng,
            };
            this.geocoder = new google.maps.Geocoder();
            this.geocoder.geocode({location: latlng}, (results: any[], status: string) => {
                if (status === google.maps.GeocoderStatus.OK) {
                    this.address.formattedAddress = results[0].formatted_address;
                    this.address.addressComponents = results[0].address_components;
                    this.address.placeId = results[0].place_id;
                    this.emitAddress();
                } else {
                    this.emitAddress();
                    console.log('Geocode was not successful: ' + status);
                }
            });
        }
    }

    emitAddress() {
        this.addressEmitted.emit(this.address);
        if(!this.address?.latitude && this.touched){
            this.errorEmited.emit(true);
        }else{
            this.errorEmited.emit(false);
        }
    }

    addressTouched(event: any) {
        this.touched = true;
        this.address.latitude = undefined;
        this.errorEmited.emit(true);
        this._changeDetectorRef.markForCheck();
    }


    animateMapZoomTo(map: google.maps.Map, targetZoom: number, currentZoom?: number) {
        currentZoom = currentZoom || map.getZoom();
        if (currentZoom !== targetZoom && currentZoom !== undefined) {
            google.maps.event.addListenerOnce(map, 'zoom_changed', () => {
                if (currentZoom) {
                    this.animateMapZoomTo(map, targetZoom, currentZoom + (targetZoom > currentZoom ? 1 : -1));
                }
            });
            setTimeout(() => {
                if (currentZoom) {
                    map.setZoom(currentZoom);
                }
            }, 80);
        }
    }


}
