<div class="flex flex-col sm:flex-row items-center md:items-start sm:justify-center md:justify-start flex-auto min-w-0">
    <div
        class="md:flex md:items-center md:justify-end w-full sm:w-auto md:h-full md:w-1/2 py-8 px-4 sm:p-12 md:p-16 sm:rounded-2xl md:rounded-none sm:shadow md:shadow-none sm:bg-card">
        <div class="w-full max-w-80 sm:w-80 mx-auto sm:mx-0">
            <!-- Logo -->


            <!-- Title -->
            <div
                class="mt-8 text-4xl font-extrabold tracking-tight leading-tight text-primary">{{"section.auth.sign-in.login" | translate}}</div>

            <div class="flex items-baseline mt-0.5 font-medium">
                <div class="text-slate-800">{{"section.auth.sign-in.create_new_acc" | translate}}</div>
                <a
                    class="ml-1 text-primary hover:underline font-bold"
                    [routerLink]="['/sign-up']">{{"section.auth.sign-in.register" | translate}}
                </a>
            </div>

            <!-- Alert -->
            <fuse-alert
                class="mt-8 -mb-4"
                *ngIf="showAlert"
                [appearance]="'outline'"
                [showIcon]="false"
                [type]="alert.type"
                [@shake]="alert.type === 'error'">
                <div [innerHTML]="alert.message"></div>
                <div *ngIf="alert?.link">
                    <span class="text-blue-700 hover:cursor-pointer hover:underline font-bold" (click)="resendLink()">{{"auth.sign-in.resend" | translate}}</span>
                </div>
            </fuse-alert>

            <!-- Sign in form -->
            <form
                class="mt-8"
                [formGroup]="signInForm"
                #signInNgForm="ngForm">

                <!-- Email field -->
                <mat-form-field class="w-full">
                    <mat-label class="text-slate-800">{{"section.auth.sign-in.login-mail" | translate}}</mat-label>
                    <input
                        id="email"
                        matInput
                        [formControlName]="'email'">
                    <mat-error *ngIf="signInForm.get('email').hasError('required')">
                        {{"section.auth.sign-in.fill-mail" | translate}}

                    </mat-error>
                    <mat-error *ngIf="signInForm.get('email').hasError('email')">
                        {{"section.auth.sign-in.fill-valid-mail" | translate}}
                    </mat-error>
                </mat-form-field>

                <!-- Password field -->
                <mat-form-field class="w-full">
                    <mat-label class="text-slate-800">{{"section.auth.sign-in.password" | translate}}</mat-label>
                    <input
                        id="password"
                        matInput
                        type="password"
                        [formControlName]="'password'"
                        #passwordField>
                    <button
                        mat-icon-button
                        type="button"
                        (click)="passwordField.type === 'password' ? passwordField.type = 'text' : passwordField.type = 'password'"
                        matSuffix>
                        <i *ngIf="passwordField.type === 'password'"
                           class="far fa-xs fa-eye"></i>
                        <i *ngIf="passwordField.type === 'text'"
                           class="far fa-xs fa-eye-slash"></i>
                    </button>
                    <mat-error>
                        {{"section.auth.sign-in.fill-password" | translate}}
                    </mat-error>
                </mat-form-field>

                <!-- Submit button -->
                <button
                    class="fuse-mat-button-large w-full mt-6 bg-primary"
                    mat-flat-button
                    [color]="'primary'"
                    [disabled]="signInForm.disabled"
                    (click)="signIn()">
                    <span *ngIf="!signInForm.disabled">
                        {{"section.auth.sign-in.login" | translate}}
                    </span>
                    <mat-progress-spinner
                        *ngIf="signInForm.disabled"
                        [diameter]="24"
                        [mode]="'indeterminate'"></mat-progress-spinner>
                </button>
                <div class="items-center justify-center text-center flex flex-col">
                    <span><a class="text-primary hover:underline font-bold" href="/forgot-password">{{"auth.forgot_pass" | translate}}</a></span>
                </div>
            </form>
        </div>
    </div>
    <div
        class="relative hidden md:flex flex-auto items-center justify-center w-1/2 h-full p-16 lg:px-28 overflow-hidden bg-primary dark:border-l">
        <!-- Background - @formatter:off -->
        <!-- Rings -->
        <svg class="absolute inset-0 pointer-events-none"
             viewBox="0 0 960 540" width="100%" height="100%" preserveAspectRatio="xMidYMax slice"
             xmlns="http://www.w3.org/2000/svg">
            <g class="color-white opacity-25" fill="none" stroke="currentColor" stroke-width="100">
                <circle r="234" cx="196" cy="23"></circle>
                <circle r="234" cx="790" cy="491"></circle>
            </g>
        </svg>
        <!-- Dots -->
        <svg class="absolute -top-16 -right-16 color-white"
             viewBox="0 0 220 192" width="220" height="192" fill="none">
            <defs>
                <pattern id="837c3e70-6c3a-44e6-8854-cc48c737b659" x="0" y="0" width="20" height="20"
                         patternUnits="userSpaceOnUse">
                    <rect x="0" y="0" width="4" height="4" fill="currentColor"></rect>
                </pattern>
            </defs>
            <rect width="220" height="192" fill="url(#837c3e70-6c3a-44e6-8854-cc48c737b659)"></rect>
        </svg>
        <!-- @formatter:on -->
        <!-- Content -->
        <div class="z-10 relative w-full max-w-2xl">
            <div class="text-7xl font-bold leading-none text-gray-100">
                <div class="w-96">
                    <img src="assets/images/logo/logo-180-white.png">
                </div>
            </div>
        </div>
    </div>
</div>
