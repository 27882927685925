<div class="container">
    <div
        class="mt-2 flex grow"
        [ngStyle.xs]="{'min-width.%': 100}"
        [ngStyle.sm]="{'width.%': 70}">
        <mat-google-maps-autocomplete class="flex grow"
            type="address"
            [country]="countries"
            [strictBounds]="true"
            [address]="address?.formattedAddress || ''"
            addressLabelText="Adresa"
            invalidErrorText=""
            [placeholderText]="placeholderText"
            [appearance]="appearance.FILL"
            (onAutocompleteSelected)="onAutocompleteSelected($event)"
            (onLocationSelected)="onLocationSelected($event)"
            (onChange)="addressTouched($event)"
        >
        </mat-google-maps-autocomplete>
        <!--        <mat-error *ngIf="mandatory && (address?.latitude === undefined)">Neplatná addresa</mat-error>-->
        <button *ngIf="showMap" mat-icon-button type="button" color="primary" (click)="toggleMap()" class="mt-6">
            <i class="icon-size-8 fas fa-map-marker-alt"></i>
        </button>

    </div>

    <mat-error class="-mt-4" *ngIf="(!address?.latitude && touched)">{{'section.geo.pick_valid_address' | translate}}</mat-error>

    <div class="map my-2" *ngIf="isMapOpen">
        <agm-map class="h-[300px] w-full"
                 [latitude]="latitude"
                 [longitude]="longitude"
                 [scrollwheel]="null"
                 [(zoom)]="zoom"
                 [disableDefaultUI]="true"
                 (mapReady)="mapReadyHandler($event)">
            <agm-marker *ngIf="this.marker"
                        [latitude]="marker.lat"
                        [longitude]="marker.lng"
                        [animation]="'DROP'"
                        [markerDraggable]="marker.draggable"
                        (markerClick)="clickedMarker()"
            ></agm-marker>
        </agm-map>

        <mat-checkbox class="-pt-4" name="customMarker" [(ngModel)]="customMarker"
                      (ngModelChange)="customMarker = $event">
            <mat-label class="fuse-mat-no-subscript">{{'section.geo.pick_own_address' | translate}}</mat-label>
        </mat-checkbox>
    </div>


</div>
