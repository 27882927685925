import {ChangeDetectorRef, Component, OnInit, ViewChild, ViewEncapsulation} from '@angular/core';
import {FormBuilder, FormGroup, NgForm, Validators} from '@angular/forms';
import {ActivatedRoute, Router} from '@angular/router';
import {FuseAlertType, fuseAnimations} from 'fuseui';
import {AuthService} from '../auth.service';
import {TranslateService} from '@ngx-translate/core';
import {ToastrService} from 'ngx-toastr';


@Component({
    selector: 'auth-sign-in',
    templateUrl: './sign-in.component.html',
    encapsulation: ViewEncapsulation.None,
    animations: fuseAnimations
})
export class AuthSignInComponent implements OnInit {

    @ViewChild('signInNgForm') signInNgForm!: NgForm;

    alert: { type: FuseAlertType; message: string; link: boolean } = {
        type: 'success',
        message: '',
        link: false
    };

    signInForm!: FormGroup;
    showAlert = false;

    constructor(
        private _activatedRoute: ActivatedRoute,
        private _authService: AuthService,
        private _formBuilder: FormBuilder,
        private _router: Router,
        private _ref: ChangeDetectorRef,
        private toastr: ToastrService,
        private translateService: TranslateService
    ) {
    }


    ngOnInit(): void {
        // Create the form
        this.signInForm = this._formBuilder.group({
            email: ['', [Validators.required, Validators.email]],
            password: ['', Validators.required],
            rememberMe: ['']
        });
    }

    resendLink(){
        const email = this.signInForm.get('email')?.value;
        this._router.navigateByUrl('resend-link/' + email);
        this._ref.markForCheck();

    }


    signIn(): void {
        // Return if the form is invalid
        if (this.signInForm.invalid) {
            return;
        }

        const email = this.signInForm.get('email')?.value;

        // Disable the form
        this.signInForm.disable();

        // Hide the alert
        this.showAlert = false;

        // Sign in

        this._authService.signIn(this.signInForm.value)
            .subscribe({
                next:
                    (data) => {
                        const redirectURL = '/dashboard';

                        // Navigate to the redirect url
                        this._router.navigateByUrl(redirectURL);

                    },
                error: (response) => {

                    // Re-enable the form
                    this.signInForm.enable();

                    // Reset the form
                    this.signInNgForm.resetForm();

                    this.signInForm.get('email')?.setValue(email);

                    //<span><a class="text-primary hover:underline font-bold" href="/forgot-password">{{"auth.forgot_pass" | translate}}</a></span>


                    if (response?.error) {
                        this.alert = {
                            type: 'error',
                            message: this.translateService.instant(response?.error?.message),
                            link: false
                        };

                        if (response?.error?.message === 'section.be-messages.user_is_not_verification' ||
                            response?.error?.message === 'Uživatel není verifikovaný') {
                            this.alert = {
                                type: 'error',
                                message: this.translateService.instant('auth.signup.verification_error'),
                                link: true
                            };
                            console.log('link');
                        }
                    } else  {
                        // Set the alert
                        this.alert = {
                            type: 'error',
                            message: this.translateService.instant('auth.signup.error'),
                            link: false
                        };
                    }

                    // Show the alert
                    this.showAlert = true;
                }
            });
    }
}
