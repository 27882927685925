import {Injectable} from '@angular/core';
import {HttpEvent, HttpHandler, HttpInterceptor, HttpRequest} from '@angular/common/http';
import {catchError, Observable, throwError} from 'rxjs';
import {LanguageService} from './language.service';


@Injectable()
export class LanguageInterceptor implements HttpInterceptor {

    constructor(private _langService: LanguageService) {
    }

    intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {

        let newReq = req.clone();

        if (this._langService.appLanguage) {
            newReq = req.clone({
                headers: req.headers
                    .set('Accept-Language', this._langService.appLanguage) // Add the language specification header
            });
        }
        return next.handle(newReq).pipe(
            catchError(error => throwError(error))
        );
    }
}
