import {Injectable} from '@angular/core';
import {TranslateService} from '@ngx-translate/core';
import {HttpClient} from '@angular/common/http';
import {catchError, map, Observable, of, ReplaySubject, shareReplay, switchMap, tap, throwError} from 'rxjs';
import {AuthUtils} from './auth.utils';
import {
    AuthorizationPasswordService,
    AuthorizationService,
    CompanyUserService, FillAresDataByIdNumberRequest, FillAresDataByNameRequest,
    LoginRequest,
    PasswordNewRequest,
    PasswordResetRequest,
    UserResource, UserService, WorkspaceUserService
} from 'qms';
import {AbstractControl, FormGroup} from '@angular/forms';
import {SendResetLinkRequest} from '../api/model/sendResetLinkRequest';
import {FillAresDataRequest} from '../api/model/fillAresDataRequest';


@Injectable()
export class AuthService {
    private _authenticated = false;

    private _user: ReplaySubject<UserResource> = new ReplaySubject<UserResource>(1);
    private observableUser: Observable<any> | null | undefined;
    private userSaved: any = null;


    constructor(
        public translateService: TranslateService,
        private _httpClient: HttpClient,
        private _accessService: AuthorizationService,
        private _companyUserService: CompanyUserService,
        private _workspaceUserService: WorkspaceUserService,
        private _authPassService: AuthorizationPasswordService,
        private _usersDsService: UserService,
    ) {
    }

    // -----------------------------------------------------------------------------------------------------
    // @ Accessors
    // -----------------------------------------------------------------------------------------------------

    /**
     * Setter & getter for access token
     */
    set accessToken(token: string) {
        console.log('Access token was set up');
        localStorage.setItem('accessToken', token);
    }

    get accessToken(): string {
        // @ts-ignore
        return localStorage.getItem('accessToken') && (localStorage.getItem('accessToken') !== 'undefined') ? localStorage.getItem('accessToken') : '';
    }

    /**
     * Setter & getter for access token
     */
    set refreshToken(token: string) {
        localStorage.setItem('refreshToken', token);
    }

    get refreshToken(): string {
        return localStorage.getItem('refreshToken') ?? '';
    }

    // -----------------------------------------------------------------------------------------------------
    // @ Public methods
    // -----------------------------------------------------------------------------------------------------

    /**
     * Forgot password
     *
     * @param email
     */
    forgotPassword(email: string): Observable<any> {

        const resetRequest: SendResetLinkRequest = {
            email: email
        };

        return this._authPassService.sendResetLink(resetRequest).pipe(
            switchMap(response =>
                // Return a new observable with the response
                of(response)
            )
        );
    }

    public changePassword(data: { email: any; token: any; password: any; passwordConfirmation: any }): Observable<any> {
        const resetRequest: PasswordResetRequest = {
            email: data.email,
            token: data.token,
            password: data.password,
            passwordConfirmation: data.passwordConfirmation,
        };

        return this._authPassService.reset(resetRequest).pipe(
            switchMap(response =>
                // Return a new observable with the response
                of(response)
            )
        );
    }

    /**
     * Reset password
     *
     * @param password
     */
    resetPassword(password: string): Observable<any> {
        return this._httpClient.post('api/auth/reset-password', password);
    }

    /**
     * Sign in
     *
     * @param credentials
     */
    signIn(credentials: { email: string; password: string }): Observable<any> {
        // Throw error, if the user is already logged in
        if (this._authenticated) {
            return throwError('User is already logged in.');
        }

        // @ts-ignore
        const credential: LoginRequest = {
            email: credentials.email,
            password: credentials.password
        };

        return this._accessService.loginUserWeb(credential).pipe(
            switchMap((response) => {


                // Store the access token in the local storage
                this.accessToken = response.accessToken;

                // Store the refresh token in the local storage
                this.refreshToken = response.refreshToken;

                // Set the authenticated flag to true
                this._authenticated = true;

                // Store the user on the user service
                // this._userService.user = response.user;

                // Return a new observable with the response
                return of(response);
            })
        );
    }

    /**
     * Refresh token
     */
    tryToRefreshToken(): Observable<any> {
        return this._httpClient.post('/api/auth/refresh-token', {
            refreshToken: this.refreshToken
        }).pipe(
            catchError(() => {

                    this._authenticated = false;
                    localStorage.removeItem('accessToken');
                    localStorage.removeItem('refreshToken');
                    return of(false);
                }
            ),
            switchMap((response: any) => {
                if (response === false) {
                    this._authenticated = false;
                    localStorage.removeItem('accessToken');
                    localStorage.removeItem('refreshToken');
                    return of(false);
                } else {
                    this.accessToken = response.accessToken;
                    this.refreshToken = response.refreshToken;
                    return of(true);
                }
            })
        );
    }


    /**
     * Sign out
     */
    signOut(): Observable<any> {

        this._accessService.logoutUser().subscribe();

        // Remove the access token from the local storage
        localStorage.removeItem('accessToken');
        localStorage.removeItem('refreshToken');

        // Set the authenticated flag to false
        this._authenticated = false;

        this.clearLoggedUserCache();

        // Return the observable
        return of(true);
    }

    /**
     * Sign up a user object
     *
     * @param user
     */
    signUp(user: any): Observable<any> {
        // Throw error, if the user is already logged in
        if (this._authenticated) {
            return throwError('User is already logged in.');
        }

        return this._accessService.registerUser(user).pipe(
            switchMap(response =>
                // Return a new observable with the response
                of(response)
            )
        );
    }

    /**
     * Unlock session
     *
     * @param credentials
     */
    unlockSession(credentials: { email: string; password: string }): Observable<any> {
        return this._httpClient.post('api/auth/unlock-session', credentials);
    }

    /**
     * Check the authentication status
     */
    check(): Observable<boolean> {
        // Check if the user is logged in
        if (this._authenticated) {
            return of(true);
        }

        // Check the access token availability
        if (!this.accessToken) {
            return of(false);
        }

        // Check the access token expire date
        if (AuthUtils.isTokenExpired(this.accessToken)) {
            console.log('expired - try to refresh');
            return this.tryToRefreshToken();
        }

        // If the access token exists and it didn't expire, sign in using it
        return of(true);
    }

    get(): Observable<UserResource> {
        return this._accessService.loggedUser();
    }


    public profile(): Observable<any> {

        if (this.userSaved) {
            return of(this.userSaved);
        } else if (this.observableUser) {
            return this.observableUser;
        } else {
            this.observableUser = this.get().pipe(
                tap((user: any) => {
                    this.userSaved = user;
                    this.observableUser = null; // Reset the observable once the user is retrieved
                }),
                shareReplay(1) // Cache the result to share with subsequent subscribers
            );
            return this.observableUser;
        }
    }

    public clearLoggedUserCache() {
        this.userSaved = null;
        this.observableUser = null;
    }

    searchFroICOName(name: FillAresDataByNameRequest): Observable<any> {
        return this._companyUserService.fillAresDataByName(name).pipe(
            switchMap(response =>
                of(response)
            )
        );
    }

    searchFroICO(ico: FillAresDataByIdNumberRequest): Observable<any> {
        return this._workspaceUserService.fillAresDataByIdNumber(ico).pipe(
            switchMap(response =>
                of(response)
            )
        );
    }

    validatePassword(control: AbstractControl): { [key: string]: any } | null {
        const value: string = control.value;
        const hasNumber = /\d/.test(value);
        const hasUppercase = /[A-Z]/.test(value);
        const hasLowercase = /[a-z]/.test(value);
        const hasMinimumLength = value && value.length >= 8;
        const conditionMessages = [];

        let errorMessage = this.translateService.instant('section.pass.mustContain');
        let error = false;

        if (!hasNumber) {
            error = true;
            conditionMessages.push(this.translateService.instant('section.pass.number'));
        }

        if (!hasUppercase) {
            error = true;
            conditionMessages.push(this.translateService.instant('section.pass.uppercase'));
        }

        if (!hasLowercase) {
            error = true;
            conditionMessages.push(this.translateService.instant('section.pass.lowercase'));
        }

        if (!hasMinimumLength) {
            error = true;
            conditionMessages.push(this.translateService.instant('section.pass.minimumLength'));
        }

        if (error) {
            if (conditionMessages.length > 2) {
                conditionMessages.splice(conditionMessages.length - 1, 0, ' a ');
                for (let i = conditionMessages.length - 3; i >= 1; i--) {
                    conditionMessages.splice(i, 0, ', ');
                }
                errorMessage += conditionMessages.join('');

            } else if (conditionMessages.length > 1) {
                conditionMessages.splice(conditionMessages.length - 1, 0, ' a ');
                errorMessage += conditionMessages.join(' ');

            } else {
                errorMessage += conditionMessages.join(' ');
            }

            return {invalidPassword: errorMessage};
        }
        return null;
    }


    validatePin(control: AbstractControl): { [key: string]: any } | null {
        const value: string = control.value;
        const isFourNumbers = /^\d{4}$/.test(value); // Checks if the value is exactly 4 digits

        if (isFourNumbers) {
            return null;
        } else {
            return {invalidPin: this.translateService.instant('section.pass.invalidPin')};
        }
    }


    checkPasswords(group: FormGroup) {

        if (!group.controls.password || !group.controls.passwordConfirmation) {
            return true;
        }
        const pass = group.controls.password.value;
        const confirmPass = group.controls.passwordConfirmation.value;
        group.controls['passwordConfirmation'].setErrors(pass === confirmPass
            ? group.controls['passwordConfirmation'].errors : {notSame: true});
        return pass === confirmPass ? null : {notSame: true};


    }

    public createPassword(
        passReq: PasswordNewRequest,
    ): Observable<any> {
        return this._authPassService.createPassword(passReq);
    }

    // public getMenuBadges(): Observable<BadgeMenuResource> {
    //     return this._usersDsService.getBadgeMenuForLoggedUser();
    // }

    async isAdmin(): Promise<boolean> {
        const response: any = await this.profile().toPromise();
        const roles = response.roles;
        return roles.some((role: any) => role.name === 'role.admin' || role.name === 'role.superadmin');
    }

    async isCompanyOrAdmin(): Promise<boolean> {
        const response: any = await this.profile().toPromise();
        const roles = response.roles;
        return roles.some((role: any) => role.name === 'role.company.admin' || role.name === 'role.seller.admin' || role.name === 'role.admin' || role.name === 'role.superadmin');
    }

    public getUser(idUser: string) {
        return this._usersDsService.getUserById(idUser).pipe(
            tap((user) => {
                this._user.next(user);
            })
        );
    }

    public resendVerification(
        email: string,
    ): Observable<any> {
        return this._usersDsService.resendEmail({email: email});
    }

    public getLogoPath(): string {
        if (this.userSaved?.company && this.userSaved?.company[0] && this.userSaved?.company[0]?.avatar) {
            return this.userSaved.company[0].avatar;
        } else if (this.userSaved?.avatar) {
            return this.userSaved.avatar;
        } else {
            return 'assets/images/logo/logo-180.png';
        }
    }

}
