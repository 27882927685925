import {Component} from '@angular/core';
import {TranslateService} from "@ngx-translate/core";
import {Title} from "@angular/platform-browser";
import {ActivatedRoute, NavigationEnd, Router} from "@angular/router";
import {filter} from "rxjs";
import {LanguageService} from "../../../../libs/qms/src/lib/utils/language/language.service";

@Component({
    selector: 'app-root',
    templateUrl: './app.component.html',
    styleUrls: ['./app.component.scss']
})
export class AppComponent {

    constructor(private translate: TranslateService,
                private titleService: Title,
                private router: Router,
                private activatedRoute: ActivatedRoute,
                private languageService: LanguageService) {
        translate.addLangs(this.languageService.supportedLanguages);

    }

    ngOnInit() {
        this.languageService.initAppLanguage();

        this.router.events.pipe(
            filter(event => event instanceof NavigationEnd),
        )
            .subscribe(() => {

                var rt = this.getChild(this.activatedRoute)

                rt.data.subscribe(data => {
                    if (data?.title) {
                        this.titleService.setTitle(data.title)
                    }
                })
            })
    }

    getChild(activatedRoute: ActivatedRoute) {
        if (activatedRoute.firstChild) {
            return this.getChild(activatedRoute.firstChild);
        } else {
            return activatedRoute;
        }

    }
}
