import { Translation, TRANSLOCO_CONFIG, TRANSLOCO_LOADER, translocoConfig, TranslocoModule, TranslocoService } from '@ngneat/transloco';
import { APP_INITIALIZER, NgModule } from '@angular/core';
import { TranslocoHttpLoader } from './transloco.http-loader';

@NgModule({
    exports  : [
        TranslocoModule
    ],
    providers: [
        {
            // Provide the default Transloco configuration
            provide : TRANSLOCO_CONFIG,
            useValue: translocoConfig({
                availableLangs      : [
                    {
                        id   : 'cs',
                        label: 'Česky'
                    },
                    {
                        id   : 'en',
                        label: 'English'
                    },
                    {
                        id   : 'de',
                        label: 'Deutschland'
                    }

                ],
                defaultLang         : 'cs',
                fallbackLang        : 'cs',
                reRenderOnLangChange: true,
                prodMode            : true
            })
        },
        {
            // Provide the default Transloco loader
            provide : TRANSLOCO_LOADER,
            useClass: TranslocoHttpLoader
        }
    ]
})
export class TranslocoCoreModule
{
}
