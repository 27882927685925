import {CountryISO, PhoneNumberFormat, SearchCountryField} from 'ngx-intl-tel-input';

export const phoneInputControls = {
    searchCountryField: [
        SearchCountryField.Iso2,
        SearchCountryField.Name
    ],
    onlyCountries: [CountryISO.CzechRepublic, CountryISO.Slovakia],
    phoneNumberFormat: PhoneNumberFormat.International,
    enablePlaceholder: false
};
