import {APP_INITIALIZER, ErrorHandler, NgModule} from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import {ExtraOptions, NoPreloading, Router, RouterModule} from '@angular/router';
import { FuseMockApiModule, FuseModule } from 'fuseui';
import { FuseConfigModule } from 'fuseui';
import { appConfig } from './core/config/app.config';

import { AppComponent } from './app.component';
import { appRoutes } from './app.routing';
import { CoreModule } from './core/core.module';
import { FuseNavigationModule, LayoutModule } from 'qms';
import { mockApiServices } from './mock-api';
import { HttpClient } from '@angular/common/http';
import { TranslateLoader, TranslateModule } from '@ngx-translate/core';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';
import { QmsModule } from '../../../../libs/qms/src/lib/qms.module';
import { ToastrModule } from 'ngx-toastr';

import { AgmCoreModule } from '@agm/core';
import { MatGoogleMapsAutocompleteModule } from '@angular-material-extensions/google-maps-autocomplete';
import * as Sentry from '@sentry/angular-ivy';

const routerConfig: ExtraOptions = {
    preloadingStrategy: NoPreloading,
    scrollPositionRestoration: 'enabled',
};

@NgModule({
    declarations: [AppComponent],
    imports: [
        BrowserModule,
        BrowserAnimationsModule,
        RouterModule.forRoot(appRoutes, routerConfig),

        // Fuse, FuseConfig & FuseMockAPI
        FuseModule,
        QmsModule,
        FuseConfigModule.forRoot(appConfig),
        FuseMockApiModule.forRoot(mockApiServices),
        // Core module of your application
        CoreModule,
        // Layout module of your application
        LayoutModule,

        BrowserAnimationsModule, // required animations module
        ToastrModule.forRoot(), // ToastrModule added

        // Translate module
        TranslateModule.forRoot({
            loader: {
                provide: TranslateLoader,
                useFactory: (http: HttpClient) => new TranslateHttpLoader(http),
                deps: [HttpClient],
            },
        }),

        AgmCoreModule.forRoot({
            apiKey: 'AIzaSyCFENVfy-_IPw3UrFDQWom5oK7MsmiSjWY',
            libraries: ['places'],
            apiVersion: '4',
            language: 'cs',
        }),
        MatGoogleMapsAutocompleteModule,
    ],
    providers: [
        {
            provide: ErrorHandler,
            useValue: Sentry.createErrorHandler({
                showDialog: false,
            }),
        },
        {
            provide: Sentry.TraceService,
            deps: [Router],
        },
        {
            provide: APP_INITIALIZER,
            useFactory: () => () => {},
            deps: [Sentry.TraceService],
            multi: true,
        },
    ],
    bootstrap: [AppComponent],
})
export class AppModule {}
