import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {MatButtonModule} from '@angular/material/button';
import {TranslateModule} from '@ngx-translate/core';
import {GoogleGeoComponent} from './google-geo/google-geo.component';
import {SharedModule} from '../../../../../../apps/fuse/src/app/shared/shared.module';
import {AgmCoreModule} from '@agm/core';
import {MatGoogleMapsAutocompleteModule} from '@angular-material-extensions/google-maps-autocomplete';
import {MatCheckboxModule} from '@angular/material/checkbox';
import {ExtendedModule} from '@angular/flex-layout';
import {MatFormFieldModule} from '@angular/material/form-field';


@NgModule({
    declarations: [
        GoogleGeoComponent
    ],
    exports: [
        GoogleGeoComponent
    ],
    imports: [
        CommonModule,
        SharedModule,
        MatButtonModule,
        TranslateModule,
        AgmCoreModule,
        MatGoogleMapsAutocompleteModule,
        MatCheckboxModule,
        ExtendedModule,
        MatFormFieldModule,
    ]
})
export class GoogleGeoModule { }
