<div class="flex flex-col sm:flex-row items-center md:items-start sm:justify-center md:justify-start flex-auto min-w-0">
    <div
        class="md:flex md:items-center md:justify-end w-full sm:w-auto md:h-full md:w-1/2 py-8 px-4 sm:p-12 md:p-16 sm:rounded-2xl md:rounded-none sm:shadow md:shadow-none sm:bg-card">
        <div class="w-full max-w-100 sm:w-100 mx-auto sm:mx-0">
            <!-- Logo -->
            <div class="w-50">
                <img src="assets/images/logo/logo-180.png">
            </div>

            <!-- Title -->
            <div
                class="mt-8 text-4xl font-extrabold tracking-tight leading-tight text-slate-800">{{"auth.signup.register" | translate}}</div>
            <div class="flex items-baseline mt-0.5 font-medium text-primary">
                <div>{{"auth.signup.already_have_acc" | translate}}</div>
                <a
                    class="ml-1 text-primary hover:underline font-bold"
                    [routerLink]="['/sign-in']">{{"auth.signup.login" | translate}}
                </a>
            </div>

            <form class="mt-8 "
                  [formGroup]="signUpForm" (ngSubmit)="signUpForm.valid && signUpForm.dirty && signUp(submitBtn)">

                <div class="lg:flex md:flex ">

                    <div class="min-w-min lg:flex-1 md:flex-1 m-3">

                        <mat-form-field class="w-full">
                            <mat-label>{{"auth.signup.name" | translate}}</mat-label>
                            <input
                                id="user-firstname"
                                matInput
                                [formControlName]="'firstname'">
                            <i matSuffix
                               *ngIf="signUpForm.get('firstname').valid && signUpForm.get('firstname')?.touched "
                               class="far text-lime-500 fa-check"></i>

                            <mat-error *ngIf="signUpForm.get('firstname').hasError('required')">
                                {{"auth.signup.fill_name" | translate}}
                            </mat-error>
                        </mat-form-field>

                        <mat-form-field class="w-full">
                            <mat-label>{{"auth.signup.surname" | translate}}</mat-label>
                            <input
                                id="user-lastname"
                                matInput
                                [formControlName]="'lastname'">
                            <i matSuffix
                               *ngIf="signUpForm.get('lastname').valid && signUpForm.get('lastname')?.touched "
                               class="far text-lime-500 fa-check"></i>

                            <mat-error *ngIf="signUpForm.get('lastname').hasError('required')">
                                {{"auth.signup.fill_surname" | translate}}
                            </mat-error>
                        </mat-form-field>

                        <mat-form-field hideRequiredMarker class="w-full">
                            <mat-label>{{"auth.signup.email" | translate}}*
                                <i [matTooltip]="translateService.instant('auth.signup.user_email_tooltip')"
                                   class="far fa-info-circle"></i>
                            </mat-label>
                            <input
                                id="user-email"
                                matInput
                                [formControlName]="'email'">
                            <i matSuffix *ngIf="signUpForm.get('email').valid && signUpForm.get('email')?.touched "
                               class="far text-lime-500 fa-check"></i>

                            <mat-error *ngIf="signUpForm.get('email').hasError('required')">
                                {{"auth.signup.fill_email" | translate}}
                            </mat-error>
                            <mat-error *ngIf="signUpForm.get('email').hasError('email')">
                                {{"auth.signup.fill_valid_email" | translate}}
                            </mat-error>
                        </mat-form-field>

                        <mat-form-field class="w-full">
                            <mat-label>{{"auth.signup.password" | translate}}</mat-label>
                            <mat-icon
                                class="icon-size-5"
                                [svgIcon]="'heroicons_solid:key'"
                                matPrefix></mat-icon>
                            <input
                                id="password"
                                matInput
                                type="password"
                                [formControlName]="'password'"
                                #passwordField>
                            <i matSuffix
                               *ngIf="signUpForm.get('password').valid && signUpForm.get('password')?.touched "
                               class="far text-lime-500 fa-check"></i>

                            <button
                                mat-icon-button
                                type="button"
                                (click)="passwordField.type === 'password' ? passwordField.type = 'text' : passwordField.type = 'password'"
                                matSuffix>
                                <i *ngIf="passwordField.type === 'password'"
                                   class="far fa-eye"></i>
                                <i *ngIf="passwordField.type === 'text'"
                                   class="far fa-eye-slash"></i>
                            </button>
                            <mat-error
                                *ngIf="signUpForm.get('password').hasError('invalidPassword')">{{ signUpForm.get('password').getError('invalidPassword') }}</mat-error>
                        </mat-form-field>

                        <mat-form-field class="w-full mt-4">
                            <mat-label>{{"auth.signup.password_again" | translate}}</mat-label>
                            <mat-icon
                                class="icon-size-5"
                                [svgIcon]="'heroicons_solid:key'"
                                matPrefix></mat-icon>
                            <input
                                [formControlName]="'passwordConfirmation'"
                                type="password"
                                matInput
                                #passwordConfirmationField>
                            <i matSuffix
                               *ngIf="signUpForm.get('passwordConfirmation').valid && signUpForm.get('passwordConfirmation')?.touched "
                               class="far text-lime-500 fa-check"></i>

                            <button
                                mat-icon-button
                                type="button"
                                (click)="passwordConfirmationField.type === 'password' ? passwordConfirmationField.type = 'text' : passwordConfirmationField.type = 'password'"
                                matSuffix>
                                <i *ngIf="passwordConfirmationField.type === 'password'"
                                   class="far fa-eye"></i>
                                <i *ngIf="passwordConfirmationField.type === 'text'"
                                   class="far fa-eye-slash"></i>
                            </button>
                            <mat-error>
                                {{"section.pass.pass_not_same" | translate}}
                            </mat-error>
                        </mat-form-field>

                        <div class="">
                            <mat-form-field class="fuse-mat-no-subscript w-full phone-input">
                                <mat-label>{{"section.signup.company_phone" | translate}}
                                    <i [matTooltip]="translateService.instant('auth.signup.phone_tooltip')"
                                       class="far fa-info-circle"></i>
                                </mat-label>
                                <input style="display: none" matInput matNativeControl placeholder="Input">
                                <ngx-intl-tel-input
                                    [enableAutoCountrySelect]="true"
                                    [enablePlaceholder]="phoneInputControls.enablePlaceholder"
                                    [onlyCountries]="phoneInputControls.onlyCountries"
                                    [searchCountryFlag]="false"
                                    [searchCountryField]="phoneInputControls.searchCountryField"
                                    [maxLength]="20"
                                    [phoneValidation]="true"
                                    [separateDialCode]="false"
                                    [numberFormat]="phoneInputControls.phoneNumberFormat"
                                    name="phone"
                                    [formControlName]="'phone'"
                                >
                                    <i matSuffix
                                       *ngIf="signUpForm.get('phone').valid && signUpForm.get('phone')?.touched "
                                       class="far text-lime-500 fa-check"></i>
                                </ngx-intl-tel-input>
                            </mat-form-field>
                            <mat-error class="-mt-4 -pt-4 text-sm font-semibold"
                                       *ngIf="signUpForm.get('phone').invalid && signUpForm.get('phone').dirty"> {{"auth.signup.valid_phone" | translate}}
                            </mat-error>
                        </div>

                        <app-google-geo
                            [showMap]="false"
                            [fullAddress]=""
                            [inLatitude]=""
                            [inLongitude]=""
                            [parsedAddress]="this.parsedAddress"
                            (addressEmitted)="addressObtained($event)">
                        </app-google-geo>


                        <div class="mb-4 mt-4 ">
                            <div class="fuse-mat-no-subscript w-full pr-0">
                                <mat-checkbox #checkBox [formControlName]="'checkBox'">

                                    <mat-label class="fuse-mat-no-subscript text-sm">
                                        {{"section.signup.terms_1" | translate }}
                                        <span>
                                        <a href="/privacy" target="_blank"
                                           class="font-medium text-blue-600 dark:text-blue-500 hover:underline">{{"section.signup.terms_4" | translate }}</a>
                                    </span>
                                        {{"section.signup.terms_5" | translate }}
                                        <span>
                                        <a href="/privacy" target="_blank"
                                           class="font-medium text-blue-600 dark:text-blue-500 hover:underline">{{"section.signup.terms_6" | translate }}</a>
                                    </span>
                                        {{"section.signup.terms_7" | translate }}
                                    </mat-label>
                                </mat-checkbox>
                                <mat-error class="font-semibold"
                                           *ngIf="signUpForm.get('checkBox').hasError('required') && signUpForm.get('checkBox').touched">
                                    {{"section.signup.agree" | translate}}
                                </mat-error>

                            </div>
                        </div>


                        <button #submitBtn type="submit"
                                class="fuse-mat-button-large w-60 mt-8"
                                mat-flat-button
                                (click)="this.signUpForm.markAsDirty(); this.signUpForm.get('checkBox').markAsTouched()"
                                [color]="'primary'"
                        >
                    <span *ngIf="!signUpForm.disabled">
                        {{"auth.signup.create_acc" | translate}}
                    </span>
                            <mat-progress-spinner
                                *ngIf="signUpForm.disabled && submitBtn.disabled"
                                [diameter]="24"
                                [mode]="'indeterminate'"></mat-progress-spinner>
                        </button>
                        <div class="ml-1 mt-1">
                            <span class="text-md">{{"auth.signup.required" | translate}}</span>
                        </div>
                    </div>
                </div>


            </form>


        </div>
    </div>
    <div
        class="relative hidden md:flex flex-auto items-center justify-center w-1/2 h-full p-16 lg:px-28 overflow-hidden bg-slate-900 dark:border-l">
        <!-- Background - @formatter:off -->
        <!-- Rings -->
        <svg class="absolute inset-0 pointer-events-none"
             viewBox="0 0 960 540" width="100%" height="100%" preserveAspectRatio="xMidYMax slice"
             xmlns="http://www.w3.org/2000/svg">
            <g class="color-white opacity-25" fill="none" stroke="currentColor" stroke-width="100">
                <circle r="234" cx="196" cy="23"></circle>
                <circle r="234" cx="790" cy="491"></circle>
            </g>
        </svg>
        <!-- Dots -->
        <svg class="absolute -top-16 -right-16 color-white"
             viewBox="0 0 220 192" width="220" height="192" fill="none">
            <defs>
                <pattern id="837c3e70-6c3a-44e6-8854-cc48c737b659" x="0" y="0" width="20" height="20"
                         patternUnits="userSpaceOnUse">
                    <rect x="0" y="0" width="4" height="4" fill="currentColor"></rect>
                </pattern>
            </defs>
            <rect width="220" height="192" fill="url(#837c3e70-6c3a-44e6-8854-cc48c737b659)"></rect>
        </svg>
        <!-- @formatter:on -->
        <!-- Content -->
        <div class="z-10 relative w-full max-w-2xl">
            <div class="text-7xl font-bold leading-none text-gray-100">
                <div class="w-96">
                    <img src="assets/images/logo/logo-180-white.png">
                </div>
            </div>
        </div>
    </div>
</div>
