import {Route} from '@angular/router';
import {AuthSignUpComponent} from './sign-up.component';

export const authSignupRoutes: Route[] = [
    {
        path     : '',
        component: AuthSignUpComponent
    },
    {
        path: ':token/:email',
        component: AuthSignUpComponent
    }
];
