import {NgModule} from '@angular/core';
import {RouterModule} from '@angular/router';
import {MatButtonModule} from '@angular/material/button';
import {MatCheckboxModule} from '@angular/material/checkbox';
import {MatFormFieldModule} from '@angular/material/form-field';
import {MatIconModule} from '@angular/material/icon';
import {MatInputModule} from '@angular/material/input';
import {MatProgressSpinnerModule} from '@angular/material/progress-spinner';
import {FuseAlertModule, FuseCardModule} from 'fuseui';
import {MatDatepickerModule} from '@angular/material/datepicker';
import {MAT_DATE_FORMATS, MAT_DATE_LOCALE} from '@angular/material/core';
import * as moment from 'moment';
import {MatSlideToggleModule} from '@angular/material/slide-toggle';
import {MatMomentDateModule} from '@angular/material-moment-adapter';
import {AuthSignUpComponent} from './sign-up.component';
import {authSignupRoutes} from './sign-up.routing';
import {SharedModule} from '../../../../../../apps/fuse/src/app/shared/shared.module';
import {TranslateModule} from '@ngx-translate/core';
import {MatSelectModule} from "@angular/material/select";
import {FormsModule} from "@angular/forms";
import {NgSelectModule} from "@ng-select/ng-select";
import {MatTooltipModule} from "@angular/material/tooltip";
import {GoogleGeoModule} from '../../utils/google-geo/google-geo.module';
import {NgxIntlTelInputModule} from 'ngx-intl-tel-input';

@NgModule({
    declarations: [
        AuthSignUpComponent
    ],
    imports: [
        RouterModule.forChild(authSignupRoutes),
        MatButtonModule,
        MatCheckboxModule,
        MatFormFieldModule,
        MatIconModule,
        MatInputModule,
        MatProgressSpinnerModule,
        FuseCardModule,
        FuseAlertModule,
        SharedModule,
        MatDatepickerModule,
        MatSlideToggleModule,
        MatMomentDateModule,
        TranslateModule,
        MatFormFieldModule,
        MatSelectModule,
        FormsModule,
        NgSelectModule,
        MatTooltipModule,
        GoogleGeoModule,
        NgxIntlTelInputModule
    ],
    providers: [
        { provide: MAT_DATE_LOCALE, useValue: 'cs-CZ' },
        {
            provide: MAT_DATE_FORMATS,
            useValue: {
                parse: {
                    dateInput: moment.ISO_8601
                },
                display: {
                    dateInput: 'LL',
                    monthYearLabel: 'MMM YYYY',
                    dateA11yLabel: 'LL',
                    monthYearA11yLabel: 'MMMM YYYY'
                }
            }
        }
    ]
})
export class AuthSignUpModule
{
}
