import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { map, Observable, ReplaySubject, tap } from 'rxjs';
import {AuthorizationService, UserResource} from "qms";

@Injectable({
    providedIn: 'root'
})
export class UserService
{
    private _user: ReplaySubject<UserResource> = new ReplaySubject<UserResource>(1);

    /**
     * Constructor
     */
    constructor(private _httpClient: HttpClient,
                private readonly _accessService: AuthorizationService)
    {
    }

    // -----------------------------------------------------------------------------------------------------
    // @ Accessors
    // -----------------------------------------------------------------------------------------------------

    /**
     * Setter & getter for user
     *
     * @param value
     */
    set user(value: UserResource)
    {
        // Store the value
        this._user.next(value);
    }

    get user$(): Observable<UserResource>
    {
        return this._user.asObservable();
    }

    // -----------------------------------------------------------------------------------------------------
    // @ Public methods
    // -----------------------------------------------------------------------------------------------------

    /**
     * Get the current logged in user data
     */
    get(): Observable<any>
    {
        return this._accessService.loggedUser();
    }

    /**
     * Update the user
     *
     * @param user
     */
    update(user: UserResource): Observable<any>
    {
        return this._httpClient.patch<UserResource>('api/common/user', {user}).pipe(
            map((response) => {
                this._user.next(response);
            })
        );
    }
}
